<template>
    <div class="orderCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            label-width="80px"
            label-position="right"
            :model="order"
            ref="editForm"
            size="small"
            :rules="rules"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="类型">
                            <el-select v-model="order.type" disabled>
                                <el-option label="采购订单" :value="0" />
                                <el-option label="单方销售" :value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="orderExtend.creator" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input v-model="orderExtend.createTime" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input placeholder="商品名称/条码/助记码/自编码" v-model="search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="销售机构">
                            <el-input readonly v-model="orderExtend.repoName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <template>
                            <el-form-item v-if="purchaseSaleOrderTypeFlag()" label="进货机构">
                                <el-input v-model="order.deptName" readonly />
                            </el-form-item>
                            <el-form-item v-else prop="deptName" label="进货机构">
                                <el-input v-model="order.deptName" />
                            </el-form-item>
                        </template>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="客户" prop="customer">
                            <el-input v-model="order.customer" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户电话" prop="customerMobile">
                            <el-input v-model="order.customerMobile" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户地址" prop="customerAddress">
                            <el-input v-model="order.customerAddress" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="order.remark" />
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button type="primary" @click="queryAndAdd" size="small" :disabled="disableQueryAndAddButton"
                    >查询并添加
                </el-button>
                <el-button
                    type="primary"
                    @click="handleSelectGoods"
                    size="small"
                    :disabled="disableShowPickUpGoodsDialog"
                    >选择商品基本信息
                </el-button>
                <el-button type="primary" @click="handleSave" size="small" :disabled="disableSaveButton"
                    >保存</el-button
                >
            </el-card>
            <pickUpGoods ref="pickUpGoods" :selfCanClose="false" @queryGoods="setSelectedGoods" />
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="order.detailParams"
                    show-summary
                    size="mini"
                    :highlight-current-row="true"
                    max-height="500"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column label="商品名称" width="180" fixed="left">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="条码" width="130">
                        <template slot-scope="scope">
                            <div v-for="b in skuByGoodsCode(scope.row.goodsCode).bars" :key="b">{{ b }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                        </template>
                    </el-table-column>
                    <el-table-column label="类目" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).category }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                        </template>
                    </el-table-column>
                    <el-table-column label="当前库存" width="100">
                        <template slot-scope="scope">
                            {{ goodsStock(scope.row.goodsCode) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="预计可出库库存" :render-header="rendHeader" width="150">
                        <template slot-scope="scope">
                            {{ goodsCanOutStock(scope.row.goodsCode) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="批发价" width="140" prop="showPrices">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showPrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input
                                    v-model="scope.row.showPrices"
                                    :precision="2"
                                    @change="computeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <template v-if="hasBigUnitPrivilege">
                        <el-table-column label="包装因子" prop="packFactors">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.packFactors'"
                                    :rules="rules.packFactors"
                                >
                                    {{ scope.row.packFactors }}
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="采购箱数" min-width="120" prop="bigUnitCounts">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.bigUnitCounts'"
                                    :rules="rules.bigUnitCounts"
                                >
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.bigUnitCounts"
                                        @change="computeCountAndMoney(scope.row)"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="散装数量" min-width="120" prop="bulkCounts">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.bulkCounts'"
                                    :rules="rules.bigUnitCounts"
                                >
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.bulkCounts"
                                        @change="computeCountAndMoney(scope.row)"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                    </template>
                    <el-table-column label="批发数量" width="120" prop="counts">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.counts'" :rules="rules.counts">
                                <ef-stock-biz-count-input
                                    v-if="hasBigUnitPrivilege"
                                    v-model="scope.row.counts"
                                    :disabled="true"
                                />
                                <ef-stock-biz-count-input
                                    v-else
                                    v-model="scope.row.counts"
                                    @change="computeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="批发金额" width="90" prop="saleShowMoney" />
                    <el-table-column label="赠品数量" width="120" prop="giftCounts">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.giftCounts'" :rules="rules.counts">
                                <ef-stock-biz-count-input
                                    v-model="scope.row.giftCounts"
                                    @change="computeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="总计数量" width="120" prop="totalCounts">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.totalCounts'"
                                :rules="rules.totalCounts"
                            >
                                <ef-stock-biz-count-input v-model="scope.row.totalCounts" :disabled="true" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="80" header-align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)"> 删除 </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import Util from 'js/Util';
import PickUpGoods from 'components/PickUpGoods';
import EfRemark from 'components/EfRemark';
import MoneyUtils from 'js/MoneyUtils';
import EfStockBizCountInput from 'components/EfStockBizCountInput';
import EfPriceInput from 'components/EfPriceInput';
import {
    deptCodeValidateRule,
    goodsCodeValidateRule,
    packFactorsValidateRule,
    pricesValidateRule,
    remarkNotRequiredValidateRule,
} from 'js/validate/ValidateCommonRule';
import { countGreaterThanZeroValidateRule, countValidateRule } from 'js/validate/ValidateStockBizRule';
import StockBizCommon, { packFactor, PropertyConverter, wholeSalePrice } from 'js/StockBizCommon';
import GoodsCommon from 'js/GoodsCommon';

const arrChangeMap = (arr) => new Map(arr.map((key, value) => [key.goodsCode, key]));

export default {
    name: 'WholeSaleOrderEdit',
    components: { PickUpGoods, EfRemark, EfStockBizCountInput, EfPriceInput },
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            search: '',
            orderExtend: {
                createTime: '',
                creator: '',
                repoName: '',
            },
            order: {
                deptName: '',
                type: null,
                repoCode: '',
                remark: '',
                customer: '',
                customerMobile: '',
                customerAddress: '',
                delCodes: [],
                detailParams: [],
            },
            //所选商品详情列表
            goodsDetailMap: new Map(),
            //所选商品库存情况列表
            goodsStockMap: new Map(),
            tableData: [],
            url: {
                queryGoods: '/goods/goods/list_c',
                save: '/trade/wholesale/order/patchExtend/',
            },
            hasBigUnitPrivilege: false,
            meta: {
                types: [],
            },
            rules: {
                repoCode: deptCodeValidateRule(),
                deptName: {
                    required: false,
                    pattern: /^[a-zA-Z\u4e00-\u9fa5]{2,30}$/,
                    message: '至少两个中文汉字或字母',
                    trigger: ['blur'],
                },
                customer: {
                    required: false,
                    pattern: /^[a-zA-Z\u4e00-\u9fa5]{2,10}$/,
                    message: '至少两个中文汉字或字母',
                    trigger: ['blur'],
                },
                customerAddress: {
                    required: false,
                    pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,
                    message: '请输入字母汉字或者数字',
                    trigger: ['blur'],
                },
                remark: remarkNotRequiredValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                packFactors: packFactorsValidateRule(),
                showPrices: pricesValidateRule(),
                counts: [
                    countValidateRule(
                        () => this.order.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                ],
                bigUnitCounts: [
                    countValidateRule(
                        () => this.order.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                ],
                totalCounts: [countGreaterThanZeroValidateRule()],
            },
        };
    },
    mounted() {
        UrlUtils.QueryRemote(this, `/trade/wholesale/order/info_c/${this.code}`, (data) => {
            Util.copyProperties(data, this.order);
            Util.copyProperties(data, this.orderExtend);
            //是否启用大单位权限
            UrlUtils.HasBigUnitPrivilege(this, this.order.repoCode, async (rst) => {
                this.hasBigUnitPrivilege = rst;
                const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(
                    data,
                    this.detailParamPropertyArrForShow(this.hasBigUnitPrivilege)
                );
                this.goodsDetailMap = arr.goodsDetails;
                this.order.detailParams = arr.detailParams;
                const goodsStocks =
                    (await this.$efApi.goodsStock.queryAllCanOutStocks([...this.goodsDetailMap.keys()])) ?? [];
                this.goodsStockMap = arrChangeMap(goodsStocks);
            });
        });
    },
    computed: {
        //禁用/启用按钮
        disableQueryAndAddButton() {
            return Util.isEmpty(this.order.repoCode) || Util.isEmpty(this.search);
        },
        //禁用/启用按钮
        disableShowPickUpGoodsDialog() {
            return Util.isEmpty(this.order.repoCode);
        },
        //禁用/启用按钮
        disableSaveButton() {
            return (
                Util.isEmpty(this.order.repoCode) ||
                this.order.detailParams == null ||
                this.order.detailParams.length === 0
            );
        },
    },
    methods: {
        detailParamPropertyArrForShow(hasBigUnitPrivilege) {
            const rst = [
                'codes',
                'goodsCode',
                'counts',
                'giftCounts',
                new PropertyConverter('prices', 'showPrices', (prices) => {
                    return MoneyUtils.moneyToYuan(prices);
                }),
                //商品库存
                'stock',
                //预计可出库库存
                'canOutStock',
            ];
            if (hasBigUnitPrivilege) {
                rst.push(
                    new PropertyConverter('packFactors', 'packFactors', (packFactors, detailParam) => {
                        return Util.isEmpty(packFactors) ? 1 : packFactors;
                    }),
                    new PropertyConverter('bigUnitCounts', 'bigUnitCounts', (bigUnitCounts, detailParam) => {
                        return Util.isEmpty(bigUnitCounts)
                            ? Math.floor(detailParam.counts / detailParam.packFactors)
                            : bigUnitCounts;
                    }),
                    new PropertyConverter('bulkCounts', 'bulkCounts', (bulkCounts, detailParam) => {
                        return Util.isEmpty(bulkCounts) ? detailParam.counts % detailParam.packFactors : bulkCounts;
                    })
                );
            }
            rst.push(
                new PropertyConverter(null, 'saleShowMoney', (source, detailParam) => {
                    return this.computeShowMoney(detailParam);
                }),
                new PropertyConverter(null, 'totalCounts', (totalCounts, detailParam) => {
                    return this.decimal(this.computeCount(detailParam)).add(detailParam.giftCounts).toNumber();
                })
            );
            return rst;
        },
        detailParamPropertyArrForAdd(hasBigUnitPrivilege) {
            //只添加必要字段
            const rst = [
                'goodsCode',
                new PropertyConverter(null, 'giftCounts', (giftCounts) => {
                    //赠品初始默认数量为0
                    return 0;
                }),
                new PropertyConverter(null, 'counts'),
                new PropertyConverter(wholeSalePrice, 'showPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
                //商品库存
                'stock',
                //预计可出库库存
                'canOutStock',
            ];
            if (hasBigUnitPrivilege) {
                rst.push(
                    new PropertyConverter(packFactor, 'packFactors', (packFactor) => {
                        return Util.isEmpty(packFactor) ? 1 : packFactor;
                    })
                );
                if (this.purchaseSaleOrderTypeFlag()) {
                    rst.push(
                        new PropertyConverter(null, 'bigUnitCounts', () => {
                            return 0;
                        }),
                        new PropertyConverter(null, 'bulkCounts', () => {
                            return 0;
                        })
                    );
                }
            }
            return rst;
        },
        purchaseSaleOrderTypeFlag() {
            return this.order.type === 0;
        },
        computeCountAndMoney(row) {
            this.$nextTick(() => {
                //计算数量和金额
                let counts;
                if (this.hasBigUnitPrivilege) {
                    //启用大单位
                    let bulkCounts = row.bulkCounts || 0;
                    const packFactors = row.packFactors || 1;
                    let bigUnitCounts = row.bigUnitCounts || 0;
                    const bigUnitCountsForBulkCounts = Math.floor(bulkCounts / packFactors);
                    if (bigUnitCountsForBulkCounts > 0) {
                        bigUnitCounts = this.decimal(bigUnitCounts).add(bigUnitCountsForBulkCounts).toNumber();
                        bulkCounts = bulkCounts % packFactors;
                        row.bulkCounts = bulkCounts;
                        row.bigUnitCounts = bigUnitCounts;
                    }
                    counts = this.computeCount(row);
                } else {
                    counts = row.counts || 0;
                }
                row.counts = counts;
                row.saleShowMoney = this.computeShowMoney(row);
                //计算总数量
                row.totalCounts = this.decimal(counts)
                    .add(row.giftCounts || 0)
                    .toNumber();
            });
        },
        computeCount(detailParam) {
            let counts;
            if (this.hasBigUnitPrivilege) {
                //启用大单位
                counts = this.decimal(detailParam.packFactors || 1)
                    .mul(detailParam.bigUnitCounts || 0)
                    .add(detailParam.bulkCounts || 0)
                    .toNumber();
            } else {
                //未启用大单位
                counts = detailParam.counts || 0;
            }
            return counts;
        },
        computeShowMoney(detailParam) {
            return this.decimal(detailParam.counts || this.computeCount(detailParam))
                .mul(detailParam.showPrices || 0)
                .toNumber();
        },
        showPickUpGoods() {
            this.$refs.pickUpGoods.show(this.pickUpGoodsQueryParam(), this.orderExtend.repoName);
        },
        handleDelete(index) {
            const detailCode = this.order.detailParams.splice(index, 1)[0].codes;
            if (Util.isEmpty(detailCode)) {
                //不存在，说明是单据详情中新增的商品被删除，则不需要处理
                return;
            }
            this.order.delCodes.push(detailCode);
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ ...this.pickUpGoodsQueryParam(), search: this.search });
            this.setSelectedGoods(rst.data);
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        goodsStock(goodsCode) {
            return this.goodsStockMap.has(goodsCode) ? this.goodsStockMap.get(goodsCode).stock ?? '--' : '--';
        },
        goodsCanOutStock(goodsCode) {
            return this.goodsStockMap.has(goodsCode) ? this.goodsStockMap.get(goodsCode).canOutStock ?? '--' : '--';
        },
        async setSelectedGoods(selectedGoods) {
            const oldDetails = Object.assign([], this.order.detailParams || []);
            await StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                oldDetails,
                this.detailParamPropertyArrForAdd(this.hasBigUnitPrivilege)
            );
            if (oldDetails.length <= 150) {
                const goodsStocks =
                    (await this.$efApi.goodsStock.queryAllCanOutStocks([...this.goodsDetailMap.keys()])) ?? [];
                this.goodsStockMap = arrChangeMap(goodsStocks);
                this.order.detailParams = oldDetails;
                this.$refs.pickUpGoods.dialogFormVisible = false;
            } else {
                this.$message.warning('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
            }
        },
        handleSelectGoods() {
            const deptName = this.orderExtend.repoName;
            this.$refs.pickUpGoods.show(this.pickUpGoodsQueryParam(), deptName);
        },
        pickUpGoodsQueryParam() {
            return {
                deptCode: this.order.repoCode,
                goodsStatusIn: [1],
                page: 1,
                limit: 100,
            };
        },
        handleSave() {
            this.$refs.editForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                if (this.order.detailParams.length > 150) {
                    this.$message.error('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
                    return false;
                }
                const params = {
                    repoCode: this.order.repoCode,
                    deptName: this.order.deptName,
                    customer: this.order.customer,
                    customerMobile: this.order.customerMobile,
                    customerAddress: this.order.customerAddress,
                    remark: this.order.remark,
                    type: this.order.type,

                    delCodes: this.order.delCodes,
                    codes: this.order.detailParams.map((r) => r.codes || null),
                    counts: this.order.detailParams.map((r) => r.counts),
                    goodsCode: this.order.detailParams.map((r) => r.goodsCode),
                    giftCounts: this.order.detailParams.map((r) => r.giftCounts),
                    packFactors: this.order.detailParams.map((r) => r.packFactors),
                    prices: this.order.detailParams.map((r) => MoneyUtils.moneyToDb(r.showPrices)),
                };
                UrlUtils.PatchRemote(
                    this,
                    this.url.save + this.code,
                    params,
                    null,
                    () => {
                        this.goBackAndReload();
                        this.$message.success('保存成功');
                    },
                    (rst) => {
                        this.$message.error(rst);
                    }
                );
            });
        },
        rendHeader(h, { column }) {
            return h(
                'div',
                {
                    style: 'display:flex;',
                },
                [
                    h('span', column.label),
                    h('prompt-message', {
                        props: {
                            messages: [
                                '预计可出库库存=商品库存-已审核待出库单据中该商品的数量，出现负数表示已审核单据中该商品预计出库的数量超过了商品实际库存。预计可出库库存不代表实际可出库的库存。',
                            ],
                        },
                    }),
                ]
            );
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
        m(money) {
            return MoneyUtils.formatMoney(money);
        },
    },
};
</script>

<style scoped>
.OrderEdit .el-form-item {
    margin-bottom: 0;
}

.OrderEdit .el-table th.gutter {
    display: table-cell;
}

.el-input {
    width: auto;
}
</style>
